import React from "react";
import "./B_details.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const Index = () => {
  const Location = useLocation();
  const Navigate = useNavigate();
  const Bus_details = Location.state;
  console.log("🚀 ~ Index ~ Bus_details:", Bus_details);

  return (
    <>
      <Navbar />
      <div className="Business_main_div">
        <div className="sub_main_bus_div">
          <div className="main_heading_div">
            <div className="back_btn_div" onClick={() => Navigate(-1)}>
              <span>&larr;</span>
            </div>
            <div className="heading_detaisl">
              <h1>Company Details</h1>
            </div>
          </div>
          <div className="main_details_bus">
            <div className="main_business_div_of_img">
              <div className="bus_imaegs_main">
                {Bus_details?.Image1 && (
                  <div className="img_box">
                    <img src={Bus_details.Image1} alt="" />
                  </div>
                )}
                {Bus_details?.Image2 && (
                  <div className="img_box">
                    <img src={Bus_details.Image2} alt="" />
                  </div>
                )}
                {Bus_details?.Image3 && (
                  <div className="img_box">
                    <img src={Bus_details.Image3} alt="" />
                  </div>
                )}
              </div>
            </div>

            <div className="Company_deatails">
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>Company name</p>
                  <h4>{Bus_details?.Organization}</h4>
                </div>
                <div className="Company_div1">
                  <p>Company category</p>
                  <h4>{Bus_details?.category}</h4>
                </div>
                <div className="Company_div1">
                  <p>Company Foundedin</p>
                  <h4>{Bus_details?.Foundedin}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Description</p>
                <h4>{Bus_details?.Description}</h4>
              </div>
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>employees Size</p>
                  <h4>{Bus_details?.Size}</h4>
                </div>
                <div className="Company_div1">
                  <p>Working Days</p>
                  <h4>{Bus_details?.WorkingDays}</h4>
                </div>
                <div className="Company_div1">
                  <p>Business Hours</p>
                  <h4>{Bus_details?.BusinessHours}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Specialty</p>
                <h4>{Bus_details?.Specialty}</h4>
              </div>
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>Phone</p>
                  <h4>{Bus_details?.Phone}</h4>
                </div>
                <div className="Company_div1">
                  <p>Website</p>
                  <a href={Bus_details?.Website}>{Bus_details?.Website}</a>
                </div>
                <div className="Company_div1">
                  <p>Country</p>
                  <h4>{Bus_details?.Country}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Street Address</p>
                <h4>{Bus_details?.StreetAddress}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
