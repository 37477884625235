import * as Yup from "yup";

export const LoginSchemas = Yup.object().shape({
  Email: Yup.string()
    .email("Invalid email address, please add @ or .com")
    .required("Please Enter Your Email"),
  Password: Yup.string().required("Please Enter Your Password"),
});

export const ContactusSchemas = Yup.object().shape({
  FullName: Yup.string().required("Please Enter Your First Name"),
  LastName: Yup.string().required("Please Enter Your Last Name"),
  Email: Yup.string().required("Please Enter Your Email"),
  Number: Yup.string().required("Please Enter Your Mobile Number"),
  Business: Yup.string().required("Please Enter Your Business name"),
  Position: Yup.string().required(
    "Please Enter Your Position in the Organisation"
  ),
  Country: Yup.string().required("Please Enter Your Country Name"),
  Message: Yup.string().required("Please Enter Your Message"),
});
export const ForgotSchemas = Yup.object().shape({
  Email: Yup.string()
    .email("Invalid email address, please add @ or .com")
    .required("Please Enter Your Email"),
});
export const SigningScheme = Yup.object().shape({
  FirstName: Yup.string().required("Please Enter Your First Name"),
  LastName: Yup.string().required("Please Enter Your Last Name"),
  Email: Yup.string()
    .email("Invalid email address")
    .required("Please Enter Your Email"),
  Number: Yup.string().required("Please Enter Your Mobile Number"),
  Company: Yup.string().required("Please Enter Your Company name"),
  Position: Yup.string().required("Please Enter Your Position"),
  DoB: Yup.string().required("Please Enter Your Date of Birth"),
  Address: Yup.string().required("Please Enter Your Address"),
  Industry: Yup.string().required("Please Enter Your Industry"),
  Gander: Yup.string().required("Please Enter Your Gander"),
  City: Yup.string().required("Please Enter Your City"),
  State: Yup.string().required("Please Enter Your State"),
  Country: Yup.string().required("Please Enter Your Country"),
  Password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Please Enter Your Password"),
});
export const EditprofileSchemas = Yup.object().shape({
  Fristname: Yup.string("Please Enter Your First name"),
  Lastname: Yup.string("Please Enter Your Last name"),
  Number: Yup.number("Please Enter Your Number"),
});

export const ChnagepasswordSchemas = Yup.object().shape({
  oldpassword: Yup.string().required("Please Enter Your old Password"),
  Newpassword: Yup.string().required("Please Enter Your New Password"),
  confirmpassword: Yup.number().required("Please Enter Your Confirm Password"),
});
