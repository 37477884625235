import React from "react";
import "./LM2.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import LM1img from "../../assets/Aboutus/Sec4_1.jpg";
import LM2data from "./LM2data";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="main_LM1_div">
        <div className="back_btn_div" onClick={() => Navigate("/")}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_LM1">
          <div className="heding_div">
            <h1>Enhanced Visibility</h1>
          </div>
          <div className="inner_div_LM1">
            <p>
              At <span className="bold_text">TurkAfrica.Directory</span>, we
              understand that visibility is key to business success. That's why
              we offer a suite of tools and resources designed to significantly
              boost the online presence of listed businesses, ensuring they
              effectively reach their target audience. Here’s how our enhanced
              visibility features can benefit your business:
            </p>

            <div className="main_card_div_LM1">
              {LM2data?.map((item, index) => {
                return (
                  <div className="card_sec4_LM1" key={index}>
                    <div className="img_card_div_LM">
                      <img src={item?.image} alt="" loading="lazy" />
                    </div>
                    <div className="text_card_div_LM1">
                      <h3>{item?.title}</h3>
                      <p>{item?.disc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <p>
              By utilizing the enhanced visibility features of TA Directory, you
              can ensure that your business reaches its target audience more
              effectively, builds a strong online presence, and attracts
              valuable partnerships and opportunities. Join TA Directory today
              and watch your business visibility soar to new heights.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
