import React, { useState, useEffect } from "react";
import "./signin.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/svgs/profile.svg";
import Company from "../../assets/svgs/Company.svg";
import Position from "../../assets/svgs/Position.svg";
import Email from "../../assets/svgs/Email.svg";
import Mobile from "../../assets/svgs/phone.svg";
import DateofBirth from "../../assets/svgs/Dob.svg";
import Address from "../../assets/svgs/location.svg";
import City from "../../assets/svgs/City.svg";
import Country from "../../assets/svgs/countrys.svg";
import Password from "../../assets/svgs/key.svg";
import Industry from "../../assets/svgs/Industry.svg";
import Gander from "../../assets/svgs/Gender.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SigningScheme } from "../../schemas";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";

import Autocomplete from "react-google-autocomplete";
import { parsePhoneNumber } from "react-phone-number-input";

const Index = () => {
  const arrow = (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6849 9.69541L0.513163 9.77765L0.49965 7.77769L12.6714 7.69545L7.27125 2.36782L8.67567 0.944295L16.506 8.66957L8.78077 16.4999L7.35725 15.0955L12.6849 9.69541Z"
        fill="white"
      />
    </svg>
  );
  const initialValues = {
    FirstName: "",
    LastName: "",
    Email: "",
    Number: "",
    Countrycode: "",
    Company: "",
    Position: "",
    DoB: "",
    Industry: "",
    Gander: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    City: "",
    State: "",
    Country: "",
    Password: "",
  };
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  const [loading, setloading] = useState(false);
  const [Countrydata, setCountrydata] = useState([]);
  const [Industrydata, setIndustrydata] = useState();

  const [dob, setDob] = useState(null);
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (dob) {
      setFieldValue("DoB", dob.toLocaleDateString("en-GB"));
    }
  }, [dob]);
  // ? County api start
  const GetCountry = async () => {
    try {
      const res = await axios.get("common/getcountry/");

      setCountrydata(res.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    GetCountry();
  }, []);
  // ? County api end
  // ? Industry api start
  const getIndustry = async () => {
    try {
      const Response = await axios.post("business/getcategory", {
        lang_id: "1",
      });

      setIndustrydata(Response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getIndustry();
  }, []);
  // ? Industry api end
  const Map_KEY = "AIzaSyBcsdjbcFxHda59ARD_5OlnUEl5NqO9oJA";
  const MYDeviceToken = JSON.parse(localStorage.getItem("DeviceToken"));

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  const GetMYtoken = async () => {
    try {
      const Response = await axios.post("/auth/getToken", {
        device_id: MYDeviceToken,
        device_type: "A",
      });
      localStorage.setItem("MYToken", JSON.stringify(Response.data.data.token));
    } catch (error) {}
  };
  useEffect(() => {
    GetMYtoken();
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    try {
      setloading(true);
      const Response = await axios.post(
        "auth/signUp",
        {
          first_name: values.FirstName,
          last_name: values.LastName,
          email: values.Email,
          ph_no: values.Number,
          country_code: values.Countrycode,
          password: values.Password,
          company_name: values.Company,
          position_company: values.Position,
          business_category: values.Industry,
          country: values.Country,
          state: values.State,
          city: values.City,
          dob: values.DoB,
          address: values.Address,
          latitude: values.Latitude.toString(),
          longitude: values.Longitude.toString(),
          gender: values.Gander,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      toast.success(Response.data.message, {
        position: "top-right",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
      setloading(false);
      resetForm();
      setValue("");
      navigate("/Login");
      setDob(null);
    } catch (error) {
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: SigningScheme,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);
    //   resetForm();
    //   setValue("");
    //   setDob(null);
    // },
  });
  const [value, setValue] = useState();
  const [countryCode, setCountryCode] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");

  useEffect(() => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber) {
        setCountryCode(parsedNumber.countryCallingCode);
        setMobileNumber(parsedNumber.nationalNumber);
        setFieldValue("Number", parsedNumber.nationalNumber);
        setFieldValue("Countrycode", parsedNumber.countryCallingCode);
      }
    }
  }, [value]);

  return (
    <>
      <div className="main_login_page_div">
        <div className="back_btn_div_auth" onClick={() => navigate(-1)}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_login">
          <div className="main_login_card">
            <div className="main_form_div_SU">
              <div className="div_of_logo" onClick={() => navigate("/")}>
                <img src={logo} alt="Logo" loading="lazy" />
                <p>Driving Prosperity for Turkish and African Enterprises</p>
              </div>
              <form onSubmit={handleSubmit} className="su_main_form_div">
                <div className="su_name_main">
                  <div className="su_fname_div">
                    <label htmlFor="FirstName">First Name</label>
                    <div className="su_fname_main_input_div">
                      <img src={profile} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="FirstName"
                        value={values.FirstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.FirstName && touched.FirstName ? (
                      <p className="errors_msg_login">{errors.FirstName}</p>
                    ) : null}
                  </div>
                  <div className="su_fname_div">
                    <label htmlFor="LastName">Last Name</label>
                    <div className="su_fname_main_input_div">
                      <img src={profile} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="LastName"
                        value={values.LastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.LastName && touched.LastName ? (
                      <p className="errors_msg_login">{errors.LastName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="su_Company_main">
                  <div className="su_Company_div">
                    <label htmlFor="Company">Company</label>
                    <div className="su_fname_main_input_div">
                      <img src={Company} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="Company"
                        value={values.Company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Company && touched.Company ? (
                      <p className="errors_msg_login">{errors.Company}</p>
                    ) : null}
                  </div>
                  <div className="su_Company_div">
                    <label htmlFor="Position">Position</label>
                    <div className="su_fname_main_input_div">
                      <img src={Position} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="Position"
                        value={values.Position}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Position && touched.Position ? (
                      <p className="errors_msg_login">{errors.Position}</p>
                    ) : null}
                  </div>
                </div>
                <div className="su_Email_number_main">
                  <div className="su_Email_div">
                    <label htmlFor="Email">Email</label>
                    <div className="su_email_main_input_div">
                      <img src={Email} alt="" loading="lazy" />
                      <input
                        type="email"
                        name="Email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Email && touched.Email ? (
                      <p className="errors_msg_login">{errors.Email}</p>
                    ) : null}
                  </div>
                  <div className="su_Mobile_div">
                    <label htmlFor="Number">Mobile</label>
                    <div className="su_Mobile_main_input_div">
                      <img src={Mobile} alt="" loading="lazy" />
                      <PhoneInput
                        className="PhoneInput_SU"
                        international
                        countryCallingCodeEditable={false}
                        value={value}
                        onChange={(e) => setValue(e)}
                      />
                    </div>
                    {errors.Number && touched.Number ? (
                      <p className="errors_msg_login">{errors.Number}</p>
                    ) : null}
                  </div>
                </div>
                <div className="su_Dob_add_main">
                  <div className="su_Dob_div">
                    <label htmlFor="DoB">Date of Birth</label>
                    <div className="su_Dob_main_input_div">
                      <img src={DateofBirth} alt="" loading="lazy" />
                      <DatePicker
                        selected={dob}
                        onChange={(date) => setDob(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        className="date_picker_input"
                      />
                    </div>
                    {errors.DoB && touched.DoB ? (
                      <p className="errors_msg_login">{errors.DoB}</p>
                    ) : null}
                  </div>
                  <div className="su_City_div">
                    <label htmlFor="Country">Industry</label>
                    <div className="su_Country_main_input_div">
                      <img src={Industry} alt="" loading="lazy" />
                      <select
                        name="Industry"
                        value={values.Industry}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option value="">Select a Industry</option>
                        {Industrydata?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="su_City_add_main">
                  <div className="su_add_div">
                    <label htmlFor="Address">Address</label>
                    <div className="su_Address_main_input_div">
                      <img src={Address} alt="" loading="lazy" />
                      <Autocomplete
                        apiKey={Map_KEY}
                        onPlaceSelected={(place) => {
                          setFieldValue("Address", place.formatted_address);
                          setFieldValue(
                            "Latitude",
                            place.geometry.location.lat()
                          );
                          setFieldValue(
                            "Longitude",
                            place.geometry.location.lng()
                          );
                        }}
                        placeholder=""
                      />
                    </div>
                    {errors.Address && touched.Address ? (
                      <p className="errors_msg_login">{errors.Address}</p>
                    ) : null}
                  </div>
                  <div className="su_City_div">
                    <label htmlFor="City">City</label>
                    <div className="su_city_main_input_div">
                      <img src={City} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="City"
                        value={values.City}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.City && touched.City ? (
                      <p className="errors_msg_login">{errors.City}</p>
                    ) : null}
                  </div>
                </div>
                <div className="su_City_add_main">
                  <div className="su_City_div">
                    <label htmlFor="City">State</label>
                    <div className="su_city_main_input_div">
                      <img src={Country} alt="" loading="lazy" />
                      <input
                        type="text"
                        name="State"
                        value={values.State}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.State && touched.State ? (
                      <p className="errors_msg_login">{errors.State}</p>
                    ) : null}
                  </div>
                  <div className="su_Country_div">
                    <label htmlFor="Country">Country</label>
                    <div className="su_Country_main_input_div">
                      <img src={Country} alt="" loading="lazy" />
                      <select
                        name="Country"
                        value={values.Country}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option value="">Select a country</option>
                        {Countrydata?.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {errors.Country && touched.Country ? (
                      <p className="errors_msg_login">{errors.Country}</p>
                    ) : null}
                  </div>
                </div>
                <div className="su_Password_add_main">
                  <div className="su_City_div">
                    <label htmlFor="Country">Gander</label>
                    <div className="su_Country_main_input_div">
                      <img src={Gander} alt="" loading="lazy" />
                      <select
                        name="Gander"
                        value={values.Gander}
                        onChange={handleChange}
                        onBlur={handleBlur}>
                        <option value="">Select a gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {errors.Gander && touched.Gander ? (
                      <p className="errors_msg_login">{errors.Gander}</p>
                    ) : null}
                  </div>
                  <div className="Email_box_login">
                    <label htmlFor="Password">Password</label>
                    <div className="input_div_pass">
                      <img src={Password} alt="" loading="lazy" />
                      <input
                        style={{ width: "80%" }}
                        type={passwordShown ? "text" : "password"}
                        id="Password"
                        name="Password"
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <i
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={passwordShown ? faEyeSlash : faEye}
                          style={{ color: "white" }}
                        />
                      </i>
                    </div>
                    {errors.Password && touched.Password ? (
                      <p className="errors_msg_login">{errors.Password}</p>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn_primry"
                  style={{ textAlign: "center", marginTop: "1rem" }}>
                  Sign Up
                </button>
              </form>
            </div>
          </div>
          <div className="main_signin_div">
            <p>
              Do you have an account ?{" "}
              <NavLink className="navlink" to={"/Login"}>
                Log in
              </NavLink>
            </p>
            {/* onClick={() => navigate("/Login")} */}
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
