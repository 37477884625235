import React, { useState } from "react";
import "./Sec1.css";

import sec1 from "../../../../assets/Aboutus/1.png";
import sec2 from "../../../../assets/Aboutus/2.png";
import sec3 from "../../../../assets/Aboutus/3.png";
import sec4 from "../../../../assets/Aboutus/4.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, EffectFade, Navigation } from "swiper/modules";

const Index = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  return (
    <div className="main_div_of_sec1" id="Home">
      <Swiper
        navigation={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        onSlideChange={handleSlideChange}
        effect={"fade"}
        modules={[Autoplay, EffectFade, Navigation]}
        className="">
        <SwiperSlide className="swiper_innerdiv">
          <div
            className="main_div_of_swiper"
            style={{
              opacity: activeSlideIndex === 0 ? 1 : 0,
              transition: "opacity 0.5s ease",
            }}>
            <div className="image_div_of_swiper">
              <img src={sec1} alt="" loading="lazy" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper_innerdiv">
          <div
            className="main_div_of_swiper"
            style={{
              opacity: activeSlideIndex === 1 ? 1 : 0,
              transition: "opacity 0.5s ease",
            }}>
            <div className="image_div_of_swiper">
              <img src={sec2} alt="" loading="lazy" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper_innerdiv">
          <div
            className="main_div_of_swiper"
            style={{
              opacity: activeSlideIndex === 2 ? 1 : 0,
              transition: "opacity 0.5s ease",
            }}>
            <div className="image_div_of_swiper">
              <img src={sec3} alt="" loading="lazy" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper_innerdiv">
          <div
            className="main_div_of_swiper"
            style={{
              opacity: activeSlideIndex === 3 ? 1 : 0,
              transition: "opacity 0.5s ease",
            }}>
            <div className="image_div_of_swiper">
              <img src={sec4} alt="" loading="lazy" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Index;
