import React from "react";
import "./Sec2.css";
import sec2img from "../../../../assets/Sec2.png";

const Index = () => {
  return (
    <div className="main_sec2_div">
      <div className="submain_div_of_sec2">
        <div className="main_text_content_div_sec2">
          <img src={sec2img} alt="" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default Index;
