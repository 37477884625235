import React, { useState, useEffect, useContext } from "react";
import "./Business.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import logo from "../../assets/Logo.png";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "react-phone-number-input";
import { useFormik } from "formik";
import Autocomplete from "react-google-autocomplete";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import Add from "../../assets/svgs/Add.svg";
import DeleteIcon from "../../assets/svgs/Delete.svg";
import { GlobalContext } from "../../Globalcontext";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const Navigate = useNavigate();
  const { MyBusinessData, setMyBusinessData } = useContext(GlobalContext);
  console.log("🚀 ~ Index ~ MyBusinessData:", MyBusinessData);
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(dayjs().startOf("day"));
  const [endTime, setEndTime] = useState(dayjs().startOf("day"));
  const formattedPickUpTime = startTime?.format("hh:mm A");
  const formattedDropTime = endTime?.format("hh:mm A");
  const initialValues = {
    Companyname: "",
    Foundedin: "",
    Description: "",
    Number: "",
    Employees: "",
    Industry: "",
    Country: "",
    WorkingDays: "",
    Specialty: "",
    Website: "",
    Starttime: " ",
    Endtime: " ",
    Address: "",
    Latitude: "",
    Longitude: "",
  };
  const Map_KEY = "AIzaSyBcsdjbcFxHda59ARD_5OlnUEl5NqO9oJA";
  const [Countrydata, setCountrydata] = useState([]);
  const [Industrydata, setIndustrydata] = useState();
  // ? County api start
  const [filteredCountries, setFilteredCountries] = useState([]);

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));

  const Userid = Userdata.id;
  // ? get my Business start api
  const GetMyBusiness = async () => {
    setLoading(true);
    try {
      const resposne = await axios.post(
        "business/get",
        { UserId: Userid.toString() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ GetMyBusiness ~ resposne:", resposne);
      setMyBusinessData(resposne.data.data);
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ GetMyBusiness ~ error:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetMyBusiness();
  }, []);
  // ? get my Business start api
  // Country API call
  const GetCountry = async () => {
    try {
      const res = await axios.get("resources/get-country", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MYToken}`,
        },
      });

      console.log("🚀 ~ GetCountry ~ res:", res);

      setFilteredCountries(res.data.data);
    } catch (error) {
      console.log("🚀 ~ GetCountry ~ error:", error);
    }
  };

  useEffect(() => {
    GetCountry();
  }, []);
  // ? County api end
  // ? Industry api start
  const getIndustry = async () => {
    try {
      const Response = await axios.post("business/getcategory", {
        lang_id: "1",
      });

      setIndustrydata(Response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getIndustry();
  }, []);
  // ? Industry api end

  //  ! Add business api callin fuction start here
  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "business/edit",
        {
          BusinessId: Userid.toString(),
          CompanyName: values.Companyname,
          Category: values.Industry,
          Foundedin: values.Foundedin.toString(),
          Description: values.Description,
          EmployeeSize: values.Employees,
          WorkingDays: values.WorkingDays,
          BusinessHours: `${values.Starttime}, ${values.Endtime}`,
          Speciality: values.Specialty,
          Phone: value,
          Website: values.Website,
          Country: values.Country,
          StreetAddress: values.Address,
          Image1: images[0]?.preview,
          Image2: images[1]?.preview,
          Image3: images[2]?.preview,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ onSubmit ~ response:", response);
      setValue();
      setLoading(false);
      Navigate("/ShowMyBusiness");
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error);
      setLoading(false);
    }
  };
  //  ! Add business api callin fuction start here
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    // validationSchema: SigningScheme,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);
    //   resetForm();
    // },
  });
  const [value, setValue] = useState();
  const [countryCode, setCountryCode] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");

  useEffect(() => {
    if (value) {
      setFieldValue("Number", value);
    }
  }, [value, setFieldValue]);
  const [images, setImages] = useState([null, null, null]);
  console.log("🚀 ~ Index ~ images:", images);
  // ! image upload  fuction start here
  const handleImageChange = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const formData = new FormData();
        formData.append("image", file);
        try {
          setLoading(true);
          const Response = await axios.post("common/uploadImage/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${MYToken}`,
            },
          });
          setLoading(false);
          console.log("🚀 ~ imageUpload ~ Response:", Response);
          const updatedImages = [...images];
          updatedImages[index] = {
            file,
            preview: Response.data.data.image_url,
          };
          setImages(updatedImages);
        } catch (error) {
          setLoading(false);
          console.log("🚀 ~ imageUpload ~ error:", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = null;
    setImages(updatedImages);
  };
  // ! image upload  fuction end here

  useEffect(() => {
    if (MyBusinessData) {
      const gettime = MyBusinessData?.BusinessHours?.split(",");
      console.log("🚀 ~ useEffect ~ gettime:", gettime[0]);
      setValues({
        Companyname: MyBusinessData.Organization,
        Foundedin: MyBusinessData.Foundedin,
        Description: MyBusinessData.Description,
        Number: MyBusinessData.Phone,
        Employees: MyBusinessData.Size,
        Industry: MyBusinessData.category,
        Country: MyBusinessData.Country,
        WorkingDays: MyBusinessData.WorkingDays,
        Specialty: MyBusinessData.Specialty,
        Website: MyBusinessData.Website,
        Starttime: gettime[0],
        Endtime: gettime[1],
        Address: MyBusinessData.StreetAddress,
      });
      // setEndTime(gettime[1]);
      setImages([
        { preview: MyBusinessData.Image1 },
        { preview: MyBusinessData.Image2 },
        { preview: MyBusinessData.Image3 },
      ]);
      setValue(MyBusinessData.Phone);
      const endtimes = gettime[1];
      setStartTime(dayjs(gettime[0], "hh:mm a"));
      setEndTime(dayjs(gettime[1], "hh:mm a"));
    }
  }, [MyBusinessData]);
  console.log(values.Starttime, "==>");

  return (
    <>
      <Navbar />
      <div className="business_container">
        <div className="sub_main_div_of_login_add">
          <div className="main_login_card_add">
            <div className="main_form_div_add">
              <div className="back_btn_mybus_div" onClick={() => Navigate(-1)}>
                <span>&larr;</span>
              </div>
              <form
                action=""
                onSubmit={handleSubmit}
                className="add_business_from">
                <div className="Company_deatails">
                  <div className="Company_name_div">
                    <div className="Company_div1">
                      <p>Company name</p>
                      <div className="compony_input_div">
                        <input
                          type="text"
                          name="Companyname"
                          value={values.Companyname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Company category</p>
                      <div className="compony_input_div">
                        <select
                          name="Industry"
                          value={values.Industry}
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option value="" selected disabled>
                            Select a Industry
                          </option>
                          {Industrydata?.map((item, index) => {
                            return (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Company Foundedin</p>
                      <div className="compony_input_div">
                        <input
                          type="number"
                          name="Foundedin"
                          value={values.Foundedin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="1990"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="company_desc_div">
                    <p>Description</p>
                    <div className="Desription_input_div">
                      <textarea
                        name="Description"
                        id="Description"
                        value={values.Description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Description"></textarea>
                    </div>
                  </div>
                  <div className="Company_name_div">
                    <div className="Company_div1">
                      <p>Employees Size</p>
                      <div className="compony_input_div">
                        <select
                          name="Employees"
                          id="Employees"
                          value={values.Employees}
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option value="">Select a Employees Size</option>
                          <option value="11-100 Employees">
                            11-100 Employees
                          </option>
                          <option value="11-200 Employees">
                            11-200 Employees
                          </option>
                          <option value="11-300 Employees">
                            11-300 Employees
                          </option>
                          <option value="11-400 Employees">
                            11-400 Employees
                          </option>
                          <option value="11-500 Employees">
                            11-500 Employees
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Working Days</p>
                      <div className="compony_input_div">
                        <select
                          name="WorkingDays"
                          id="WorkingDays"
                          value={values.WorkingDays}
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option value="">Select a Working Days</option>
                          <option value="Monday to Friday">
                            Monday to Friday
                          </option>
                          <option value="Monday to Saturday">
                            Monday to Saturday
                          </option>
                          <option value="All Week Days">All Week Days</option>
                        </select>
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Business Hours</p>
                      <div className="time_div">
                        <div className="start_time_div">
                          <label htmlFor="Startpoint">Start Time </label>
                          <div className="compony_input_div_time">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                value={startTime}
                                onChange={(newValue) => {
                                  setStartTime(newValue);
                                  setFieldValue(
                                    "Starttime",
                                    newValue.format("hh:mm a")
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="start_time_div">
                          <label htmlFor="DropTime">End Time</label>
                          <div className="compony_input_div_time">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <MobileTimePicker
                                value={endTime}
                                onChange={(newValue) => {
                                  setEndTime(newValue);
                                  setFieldValue(
                                    "Endtime",
                                    newValue.format("hh:mm a")
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="company_desc_div">
                    <p>Specialty</p>
                    <div className="Desription_input_div">
                      <textarea
                        name="Specialty"
                        id="Specialty"
                        value={values.Specialty}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Specialty"></textarea>
                    </div>
                  </div>
                  <div className="Company_name_div">
                    <div className="Company_div1">
                      <p>Phone</p>
                      <div className="compony_input_div">
                        <PhoneInput
                          className="PhoneInput_SU"
                          international
                          countryCallingCodeEditable={false}
                          value={value}
                          onChange={(e) => setValue(e)}
                        />
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Website</p>
                      <div className="compony_input_div">
                        <input
                          type="text"
                          name="Website"
                          id="Website"
                          value={values.Website}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="http://www.Wensite Name.co/"
                        />
                      </div>
                    </div>
                    <div className="Company_div1">
                      <p>Country</p>
                      <div className="compony_input_div">
                        <select
                          name="Country"
                          value={values.Country}
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option value="">Select a country</option>
                          {filteredCountries?.map((item, index) => {
                            return (
                              <option value={item.id} key={index}>
                                {item.Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="company_desc_div">
                    <p>Street Address</p>
                    <div className="compony_input_div">
                      <input
                        type="text"
                        name="Address"
                        id="Address"
                        value={values.Address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Street Address"
                      />
                      {/* <Autocomplete
                        apiKey={Map_KEY}
                        onPlaceSelected={(place) => {
                          setFieldValue("Address", place.formatted_address);
                          setFieldValue(
                            "Latitude",
                            place.geometry.location.lat()
                          );
                          setFieldValue(
                            "Longitude",
                            place.geometry.location.lng()
                          );
                        }}
                        placeholder="Street Address"
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="ulopd_image_div_section">
                  <h3>Upload Your Company Images</h3>
                  <div className="image_upload_main_div">
                    {images.map((image, index) => (
                      <div className="img_box_buisness" key={index}>
                        <input
                          type="file"
                          name={`upload_img_${index}`}
                          id={`upload_img_${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleImageChange(event, index)}
                        />
                        <label
                          htmlFor={`upload_img_${index}`}
                          className="uploadicon">
                          {image?.preview ? (
                            <>
                              <img
                                src={image.preview}
                                className="previewimage"
                                alt={`Preview ${index}`}
                              />
                              <button
                                type="button"
                                className="delete-icon"
                                onClick={() => handleImageDelete(index)}>
                                <img src={DeleteIcon} alt="Delete icon" />
                              </button>
                            </>
                          ) : (
                            <div className="upload_message_div">
                              <p>
                                {index === 0
                                  ? "Please upload your company logo."
                                  : "Please upload a landscape image."}
                              </p>

                              <img src={Add} alt="Upload icon" />
                            </div>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn_primry_Add_business"
                  style={{ textAlign: "center" }}>
                  Update Business
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}>
            <span className="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
