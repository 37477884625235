import React, { useState, useEffect, useContext } from "react";
import "./B_details.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { GlobalContext } from "../../Globalcontext";

const Index = () => {
  const Location = useLocation();
  const Navigate = useNavigate();
  const Bus_details = Location.state;
  const [loading, setLoading] = useState(false);
  const { MyBusinessData, setMyBusinessData } = useContext(GlobalContext);

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));

  const Userid = Userdata.id;

  const GetMyBusiness = async () => {
    setLoading(true);
    try {
      const resposne = await axios.post(
        "business/get",
        { UserId: Userid.toString() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ GetMyBusiness ~ resposne:", resposne);
      setMyBusinessData(resposne.data.data);
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ GetMyBusiness ~ error:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetMyBusiness();
  }, []);

  return (
    <>
      <Navbar />
      <div className="Business_main_div">
        <div className="sub_main_bus_div">
          <div className="main_heading_div">
            <div className="back_btn_div" onClick={() => Navigate(-1)}>
              <span>&larr;</span>
            </div>
            <div className="heading_detaisl">
              <button
                onClick={() => Navigate("/EditMyBusiness")}
                className="btn_primry_Add_business"
                style={{ textAlign: "center" }}>
                Edit Business
              </button>
            </div>
          </div>
          <div className="main_details_bus">
            <div className="main_business_div_of_img">
              <div className="bus_imaegs_main">
                {MyBusinessData?.Image1 && (
                  <div className="img_box">
                    <img src={MyBusinessData.Image1} alt="" loading="lazy" />
                  </div>
                )}
                {MyBusinessData?.Image2 && (
                  <div className="img_box">
                    <img src={MyBusinessData.Image2} alt="" loading="lazy" />
                  </div>
                )}
                {MyBusinessData?.Image3 && (
                  <div className="img_box">
                    <img src={MyBusinessData.Image3} alt="" loading="lazy" />
                  </div>
                )}
              </div>
            </div>

            <div className="Company_deatails">
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>Company name</p>
                  <h4>{MyBusinessData?.Organization}</h4>
                </div>
                <div className="Company_div1">
                  <p>Company category</p>
                  <h4>{MyBusinessData?.category}</h4>
                </div>
                <div className="Company_div1">
                  <p>Company Foundedin</p>
                  <h4>{MyBusinessData?.Foundedin}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Description</p>
                <h4>{MyBusinessData?.Description}</h4>
              </div>
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>employees Size</p>
                  <h4>{MyBusinessData?.Size}</h4>
                </div>
                <div className="Company_div1">
                  <p>Working Days</p>
                  <h4>{MyBusinessData?.WorkingDays}</h4>
                </div>
                <div className="Company_div1">
                  <p>Business Hours</p>
                  <h4>{MyBusinessData?.BusinessHours}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Specialty</p>
                <h4>{MyBusinessData?.Specialty}</h4>
              </div>
              <div className="Company_name_div">
                <div className="Company_div1">
                  <p>Phone</p>
                  <h4>{MyBusinessData?.Phone}</h4>
                </div>
                <div className="Company_div1">
                  <p>Website</p>
                  <a href={MyBusinessData?.Website}>
                    {MyBusinessData?.Website}
                  </a>
                </div>
                <div className="Company_div1">
                  <p>Country</p>
                  <h4>{MyBusinessData?.Country}</h4>
                </div>
              </div>
              <div className="company_desc_div">
                <p>Street Address</p>
                <h4>{MyBusinessData?.StreetAddress}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}>
            <span className="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
