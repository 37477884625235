// useFirebaseToken.js
import { useEffect, useState } from "react";
import { getMessaging, getToken } from "firebase/messaging";
import { app } from "../Firebase/firebase";

const useFirebaseToken = () => {
  const [token, setToken] = useState(null);

  const messaging = getMessaging(app);

  useEffect(() => {
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          // console.log("FCM Token:", currentToken);
          // dispatch(addFCM(currentToken));
          localStorage.setItem("DeviceToken", JSON.stringify(currentToken));
        } else {
          console.log("No registration token available.");
        }
      })
      .catch((error) => {
        console.error("Error getting FCM token:", error);
      });
  }, []);

  const MYDeviceToken = JSON.parse(localStorage.getItem("DeviceToken"));
};

export default useFirebaseToken;
