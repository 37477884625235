import React from "react";
import "./LM3.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import LM1img from "../../assets/LM3.png";
// import LM1data from "./LM1data";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="main_LM1_div">
        <div className="back_btn_div" onClick={() => Navigate("/")}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_LM1">
          <div className="heding_div">
            <h1>Networking Opportunities</h1>
          </div>
          <div className="inner_div_LM3">
            <div className="image_of_LM3">
              <img src={LM1img} alt="" loading="lazy" />
            </div>
            <div className="text_LM3">
              <p>
                <span className="bold_text">Download TurkAfrica B2B App</span>{" "}
                and Join us in a dynamic platform where Africa and Türkiye's
                brightest business minds converge. TurkAfrica® is more than just
                a B2B platform; it's a breeding ground for collaboration,
                innovation, and growth. Here, businesses from across Africa and
                Türkiye unite to network, share ideas, and forge valuable
                connections.
              </p>

              <p>
                Network, strike deals, and partner with distributors,
                manufacturers, exporters, importers, investors, licensors,
                licensees, wholesalers, and retailers, among others.
              </p>
              <p>
                Embrace the opportunity to be part of an open community of
                visionary entrepreneurs and business leaders. Together, we're
                dedicated to fostering and advancing business interests that
                benefit not just individual enterprises, but entire regions
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
