import React from "react";
import "./Home.css";
import Sec1 from "./Sections/Section1/index";
import Sec2 from "./Sections/Section2/index";
import Sec3 from "./Sections/Section3/index";
import Sec4 from "./Sections/Section4/index";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import ScrollToTop from "../../components/UI/ScrollTop";

const Index = () => {
  return (
    <div className="main_div_of_home">
      <ScrollToTop />
      <Navbar />
      <Sec1 />
      <Sec2 />
      <Sec3 />
      <Sec4 />
      <Footer />
    </div>
  );
};

export default Index;
