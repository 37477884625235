import LM1img from "../../assets/LM2/LM2_1.png";
import LM2img from "../../assets/LM2/LM2_2.png";
import LM3img from "../../assets/LM2/LM2_3.png";
import LM4img from "../../assets/LM2/LM2_4.png";
import LM5img from "../../assets/LM2/LM2_5.png";
import LM6img from "../../assets/LM2/LM2_6.png";
import LM7img from "../../assets/LM2/LM2_7.png";
import LM8img from "../../assets/LM2/LM2_8.png";
import LM9img from "../../assets/LM2/LM2_9.png";
import LM10img from "../../assets/LM2/LM2_10.png";
import LM11img from "../../assets/LM2/LM2_11.png";
import LM12img from "../../assets/LM2/LM2_12.png";

const LM2Data = [
  {
    id: 1,
    image: LM1img,
    title: "Premium Listings",
    disc: "Gain higher visibility with premium listings that are prominently displayed in search results and highlighted in relevant categories. This ensures that your business stands out to potential partners and clients.",
  },
  {
    id: 2,
    image: LM2img,
    title: "Search Engine Optimization (SEO)",
    disc: "Our platform is optimized for search engines, ensuring that your business profile ranks high in search results. This increased visibility drives more traffic to your listing and enhances your online presence.",
  },
  {
    id: 3,
    image: LM3img,
    title: "Customized Business Profiles",
    disc: "Create a compelling and informative profile with customized descriptions, keywords, and multimedia content. A well-crafted profile helps attract and engage your target audience more effectively.",
  },
  {
    id: 4,
    image: LM4img,
    title: "Featured Business Spotlights",
    disc: "Verified Listings: To ensure the reliability and credibility of the businesses listed, we implement a thorough verification process. This includes validating business licenses, checking references, and confirming contact information, so you can connect with confidence.",
  },
  {
    id: 5,
    image: LM5img,
    title: "User Reviews and Ratings",
    disc: " Be featured in our Business Spotlight section on the homepage and in newsletters. This prime real estate increases your exposure to a broader audience and showcases your business as a leader in your industry.",
  },
  {
    id: 6,
    image: LM6img,
    title: "Targeted Advertising",
    disc: "Utilize our targeted advertising options to reach specific demographics and industries. Whether you want to focus on a particular region, industry, or business size, our advertising tools ensure your message reaches the right audience.",
  },
  {
    id: 7,
    image: LM7img,
    title: "Content Marketing and Blogs",
    disc: " Publish articles, success stories, and industry insights on our platform. By sharing valuable content, you position your business as a thought leader, attracting more visitors to your profile and building trust with potential partners.",
  },
  {
    id: 8,
    image: LM8img,
    title: "Email Marketing Campaigns",
    disc: "Participate in our targeted email marketing campaigns to promote your business to our extensive database of users. These campaigns highlight featured businesses and special offers, driving traffic and generating leads.",
  },
  {
    id: 9,
    image: LM9img,
    title: "Analytics and Insights",
    disc: "Access detailed analytics to track the performance of your listing. Understand who is viewing your profile, how they are interacting with your content, and which marketing efforts are driving the most engagement. Use these insights to refine your strategy and maximize your visibility",
  },
  {
    id: 10,
    image: LM10img,
    title: "Local SEO",
    disc: "Optimize your presence for local searches within Türkiye and African countries. Our platform ensures that your business is easily discoverable by local clients and partners looking for services and products in their vicinity.",
  },
  {
    id: 11,
    image: LM11img,
    title: "Customer Reviews and Testimonials",
    disc: "Encourage satisfied clients to leave positive reviews and testimonials on your profile. This social proof enhances your credibility and attracts more business by showing potential partners the quality of your services.",
  },
  {
    id: 12,
    image: LM12img,
    title: "Networking Opportunities",
    disc: "Participate in our virtual and physical networking events to connect with other businesses, share experiences, and create synergies. These events provide additional exposure and opportunities to showcase your business to a captive audience.",
  },
];

export default LM2Data;
