import LM1img from "../../assets/LM1/LM1.png";
import LM2img from "../../assets/LM1/LM2.png";
import LM3img from "../../assets/LM1/LM3.png";
import LM4img from "../../assets/LM1/LM4.png";
import LM5img from "../../assets/LM1/LM5.png";
import LM6img from "../../assets/LM1/LM6.png";
import LM7img from "../../assets/LM1/LM7.png";
import LM8img from "../../assets/LM1/LM8.png";

const LM1Data = [
  {
    id: 1,
    image: LM1img,
    title: "Extensive Database",
    disc: "directory features thousands of businesses across diverse industries, including manufacturing, agriculture, technology, services, and more. Whether you are looking for suppliers, distributors, or service providers, our vast database ensures that  you can find the right match for your needs.",
  },
  {
    id: 2,
    image: LM2img,
    title: "Detailed Business Profiles",
    disc: "Each listing includes in-depth information about the business, such as company history, product or service offerings, certifications, and contact details. This transparency helps you make informed decisions and build trust with potential partners.",
  },
  {
    id: 3,
    image: LM3img,
    title: "Advanced Search and Filter Options ",
    disc: "Our powerful search engine allows you to find businesses based on specific criteria, such as industry, location, company size, and more. Advanced filters enable you to narrow down your search and quickly identify the most relevant partners.",
  },
  {
    id: 4,
    image: LM4img,
    title: "Verified Listings",
    disc: "Verified Listings: To ensure the reliability and credibility of the businesses listed, we implement a thorough verification process. This includes validating business licenses, checking references, and confirming contact information, so you can connect with confidence.",
  },
  {
    id: 5,
    image: LM5img,
    title: "User Reviews and Ratings",
    disc: "Benefit from the experiences of other users by reading reviews and ratings of businesses. This community-driven feedback system helps you gauge the reputation and performance of potential partners before making any commitments.",
  },
  {
    id: 6,
    image: LM6img,
    title: "Multimedia Content",
    disc: "  Enhance your understanding of listed businesses through rich multimedia content, such as photos, videos, and virtual tours. This feature allows you to get a comprehensive view of the company's facilities, products, and services without leaving your office.",
  },
  {
    id: 7,
    image: LM7img,
    title: "Regular Updates",
    disc: " Our team continuously updates the directory to reflect the latest information and emerging businesses. This ensures that you always have access to the most current data and opportunities in the market.",
  },
  {
    id: 8,
    image: LM8img,
    title: "Global Reach, Local Insight",
    disc: "While we connect businesses across continents, we also provide local insights and market intelligence to help you navigate the unique business landscapes of Türkiye and African countries. Our local expertise ensures that you can engage effectively and successfully with partners from different regions.",
  },
];

export default LM1Data;
