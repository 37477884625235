import React from "react";
import "./LM1.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import LM1img from "../../assets/Aboutus/Sec4_1.jpg";
import LM1data from "./LM1data";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className="main_LM1_div">
        <div className="back_btn_div" onClick={() => Navigate("/")}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_LM1">
          <div className="heding_div">
            <h1>Comprehensive Listings</h1>
          </div>
          <div className="inner_div_LM1">
            <p>
              At <span className="bold_text">TurkAfrica.Directory</span>, we
              pride ourselves on offering an extensive and meticulously curated
              database of businesses from Türkiye and African countries. Our
              platform is designed to make it effortless for users to find
              reliable partners and seize valuable business opportunities.
              Here's how our comprehensive listings can benefit you:
            </p>

            <div className="main_card_div_LM1">
              {LM1data?.map((item, index) => {
                return (
                  <div className="card_sec4_LM1" key={index}>
                    <div className="img_card_div_LM">
                      <img src={item?.image} alt="" loading="lazy" />
                    </div>
                    <div className="text_card_div_LM1">
                      <h3>{item?.title}</h3>
                      <p>{item?.disc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <p>
              By leveraging the comprehensive listings on TA Directory, you can
              efficiently identify and connect with reliable business partners,
              explore new markets, and drive growth and success for your
              enterprise. Join us today and discover a world of opportunities
              waiting for you in Türkiye and Africa.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
