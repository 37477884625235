import React, { useContext } from "react";

import "./Sec4.css";
import Sec4_1 from "../../../../assets/Aboutus/Sec4_1.jpg";
import Sec4_2 from "../../../../assets/Aboutus/Sec4_2.jpg";
import Sec4_3 from "../../../../assets/Aboutus/Sec4_3.jpg";
import { GlobalContext } from "../../../../Globalcontext";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const Navigate = useNavigate();
  const { setFirstModalShow, setSecondModalShow, setThirdModalShow } =
    useContext(GlobalContext);

  return (
    <div className="main_sec4_div">
      <div className="sub_main_div_of_sec4">
        <div className="heding_div_of_sec4">
          <h2>At TurkAfrica.Directory, we offer:</h2>
        </div>
        <div className="sec4_main_content_div">
          <div className="main_card_div">
            <div className="card_sec4">
              <div className="img_card_div">
                <img src={Sec4_1} alt="" loading="lazy" />
              </div>
              <div className="text_card_div">
                <h3>Comprehensive Listings</h3>
                <p>
                  An extensive database of businesses from Türkiye and African
                  countries, making it easy to find reliable partners and
                  opportunities.
                </p>
              </div>
              <p
                className="link_sec4"
                onClick={() => Navigate("/ComprehensiveListings")}>
                Learn more
              </p>
            </div>
            <div className="card_sec4">
              <div className="img_card_div">
                <img src={Sec4_2} alt="" loading="lazy" />
              </div>
              <div className="text_card_div">
                <h3>Enhanced Visibility</h3>
                <p>
                  Tools and resources to boost the online presence of listed
                  businesses, ensuring they reach their target audience
                  effectively
                </p>
              </div>
              <p
                className="link_sec4"
                onClick={() => Navigate("/EnhancedVisibility")}>
                Learn more
              </p>
            </div>
            <div className="card_sec4">
              <div className="img_card_div">
                <img src={Sec4_3} alt="" loading="lazy" />
              </div>
              <div className="text_card_div">
                <h3>Networking Opportunities</h3>
                <p>
                  A platform for businesses to engage, collaborate, and
                  establish valuable connections that drive growth and
                  innovation.
                </p>
              </div>
              <p
                className="link_sec4"
                onClick={() => Navigate("/NetworkingOpportunities")}>
                Learn more
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
