import React from "react";
import "./Sec3.css";
import sec3img from "../../../../assets/Aboutus/sec_3.jpeg";

const Section2 = () => {
  return (
    <div className="section2" id="AboutUs">
      <div className="submain_sec2">
        <div className="sec2_textbox">
          <h1>About Us</h1>
          <p>
            <b> TurkAfrica.Directory</b> is a pioneering business directory
            dedicated to fostering connections between Türkiye and African
            countries. As the first of its kind, TurkAfrica.Directory Directory
            aims to revolutionize the way businesses from these diverse regions
            interact and grow on the global stage.
          </p>

          <p>
            Our mission is to promote, empower, and elevate African and Turkish
            businesses by enhancing their online presence and facilitating
            meaningful partnerships. We believe in the untapped potential of
            these regions and are committed to providing a platform where
            companies can discover and connect with the right business partners,
            fostering mutual growth and success.
          </p>
        </div>
        <div className="sec2_img_box">
          <img src={sec3img} alt="Not found" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
