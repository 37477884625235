import React, { useState, useEffect } from "react";
import "./Business.css";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import busimg1 from "../../assets/Aboutus/Sec4_1.jpg";
import Country from "../../assets/svgs/countrys.svg";
import Mobile from "../../assets/svgs/phone.svg";
import Address from "../../assets/svgs/location.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const CountrieName = location.state;
  const [loading, setloading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [Pagination, setPagination] = useState();
  const [Businesslists, setBusinesslists] = useState();
  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  const Businesslist = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "resources/get-industries-list",
        { page: activePage, category: CountrieName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      setPagination(response.data);
      setBusinesslists(response.data.data.rows);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    Businesslist();
  }, []);
  useEffect(() => {
    Businesslist();
  }, [activePage]);

  const handlePageClick = (pageNumber) => () => {
    setActivePage(pageNumber);
  };

  return (
    <>
      <Navbar />
      <div className="Business_main_div">
        <div className="sub_main_bus_div">
          <div className="main_heading_div">
            <div className="back_btn_div" onClick={() => navigate(-1)}>
              <span>&larr;</span>
            </div>
            <div className="heading_detaisl">
              <h1>Company Details</h1>
            </div>
          </div>
          <div className="Business_card_main">
            {Businesslists?.map((item, index) => {
              return (
                <div className="Business_card_div" key={index}>
                  <div className="image_bus_div">
                    <img src={item.Image1} alt="" />
                    <div className="div_bus_name">
                      <h2>{item.Organization}</h2>
                    </div>
                  </div>
                  <div className="text_bus_div_main">
                    <div className="text_dtails_div">
                      <img src={Address} alt="" />
                      <p>{item.StreetAddress}</p>
                    </div>
                    <div className="text_dtails_div">
                      <img src={Mobile} alt="" />
                      <p>{item.Phone}</p>
                    </div>
                    <div className="text_dtails_div">
                      <img src={Country} alt="" />
                      <a href={item.Website}>{item.Website}</a>
                    </div>
                    <div className="text_dtails_div">
                      <img src={Country} alt="" />
                      <p>{item.Country}</p>
                    </div>
                  </div>
                  <div
                    className="div_of_show_details_btn"
                    onClick={() =>
                      navigate("/BusinessDetails", { state: item })
                    }>
                    <p>See more</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="pagination_div_main">
          <div className="total_page">
            <p> Total Business:- </p>
            <h3>{Pagination?.totalItems}</h3>
          </div>
          <div className="pagination_div">
            <nav aria-label="Page navigation example">
              <MDBPagination className="mb-0">
                <MDBPaginationItem disabled={activePage === 1}>
                  <MDBPaginationLink onClick={handlePageClick(activePage - 1)}>
                    &#8592;
                  </MDBPaginationLink>
                </MDBPaginationItem>
                {Array.from(
                  { length: Pagination?.totalPages },
                  (_, index) => index + 1
                ).map((pageNumber) => (
                  <MDBPaginationItem
                    key={pageNumber}
                    active={pageNumber === activePage}>
                    <MDBPaginationLink onClick={handlePageClick(pageNumber)}>
                      {pageNumber}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
                <MDBPaginationItem
                  disabled={activePage === Pagination?.totalPages}>
                  <MDBPaginationLink onClick={handlePageClick(activePage + 1)}>
                    &#8594;
                  </MDBPaginationLink>
                </MDBPaginationItem>
              </MDBPagination>
            </nav>
          </div>
        </div>
      </div>
      <Footer />
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
