import { createContext, useState } from "react";

export const GlobalContext = createContext();
const GlobalContextProvider = ({ children }) => {
  const [FirstModalShow, setFirstModalShow] = useState(false);
  const [SecondModalShow, setSecondModalShow] = useState(false);
  const [ThirdModalShow, setThirdModalShow] = useState(false);
  const [FourModalShow, setFourModalShow] = useState(false);
  const [FiveModalShow, setFiveModalShow] = useState(false);
  const [profiledata, setprofileData] = useState();
  const [changePasswordshow, setchangePasswordshow] = useState(false);
  const [LogoutModalshow, setLogoutModalshow] = useState(false);
  const [MyBusinessData, setMyBusinessData] = useState();

  return (
    <GlobalContext.Provider
      value={{
        FirstModalShow,
        setFirstModalShow,
        SecondModalShow,
        setSecondModalShow,
        ThirdModalShow,
        setThirdModalShow,
        FourModalShow,
        setFourModalShow,
        FiveModalShow,
        setFiveModalShow,
        profiledata,
        setprofileData,
        // Chnagepasword model
        changePasswordshow,
        setchangePasswordshow,
        //  Log out model
        LogoutModalshow,
        setLogoutModalshow,
        // for My business data
        MyBusinessData,
        setMyBusinessData,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
