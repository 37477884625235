import React, { useContext, useState, useEffect } from "react";
import "./Profile.css";
import { GlobalContext } from "../../Globalcontext";
import defultimg from "../../assets/defultimg.png";
import Navbar from "../../components/UI/Navbar/Index";
import Footer from "../../components/UI/Footer";
import profile from "../../assets/svgs/profile.svg";
import Company from "../../assets/svgs/Company.svg";
import Position from "../../assets/svgs/Position.svg";
import Email from "../../assets/svgs/Email.svg";
import Mobile from "../../assets/svgs/phone.svg";
import DateofBirth from "../../assets/svgs/Dob.svg";
import Address from "../../assets/svgs/location.svg";
import City from "../../assets/svgs/City.svg";
import Country from "../../assets/svgs/countrys.svg";
import Industry from "../../assets/svgs/Industry.svg";
import Gender from "../../assets/svgs/Gender.svg";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import Autocomplete from "react-google-autocomplete";
import { date } from "yup";

const AnimatedSVG = () => {
  const [value, setValue] = useState();
  const [dob, setDob] = useState(null);
  console.log("🚀 ~ AnimatedSVG ~ dob:", dob);
  const { profiledata, setprofileData } = useContext(GlobalContext);
  const [countryCode, setCountryCode] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  console.log("🚀 ~ AnimatedSVG ~ imageUrl:", imageUrl);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const initialValues = {
    FirstName: "",
    LastName: "",
    Email: "",
    Number: "",
    Countrycode: "",
    Company: "",
    Position: "",
    DoB: "",
    Industry: "",
    Gender: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    City: "",
    State: "",
    Country: "",
    Password: "",
  };

  const [editMode, setEditMode] = useState(false);

  const handleEditToggle = () => setEditMode(!editMode);

  const editicon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10_151)">
        <path
          d="M6.414 16L16.556 5.858L15.142 4.444L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.322C14.6225 2.13453 14.8768 2.02921 15.142 2.02921C15.4072 2.02921 15.6615 2.13453 15.849 2.322L18.678 5.151C18.8655 5.33853 18.9708 5.59284 18.9708 5.858C18.9708 6.12316 18.8655 6.37747 18.678 6.565L7.243 18ZM3 20H21V22H3V20Z"
          fill="#e30a17"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_151">
          <rect width="24" height="24" fill="#e30a17" />
        </clipPath>
      </defs>
    </svg>
  );

  const mobile = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.538C10.475 3.538 8.428 3.706 6.995 3.845C6.70773 3.87185 6.43924 3.99957 6.23715 4.2055C6.03507 4.41143 5.91243 4.68227 5.891 4.97C5.75 6.818 5.548 9.813 5.548 12C5.548 14.187 5.749 17.183 5.891 19.03C5.937 19.627 6.401 20.098 6.995 20.155C8.428 20.295 10.475 20.461 12 20.461C13.525 20.461 15.572 20.294 17.005 20.155C17.2923 20.1282 17.5608 20.0004 17.7628 19.7945C17.9649 19.5886 18.0876 19.3177 18.109 19.03C18.251 17.182 18.452 14.187 18.452 12C18.452 9.813 18.251 6.818 18.109 4.97C18.0876 4.68227 17.9649 4.41143 17.7628 4.2055C17.5608 3.99957 17.2923 3.87185 17.005 3.845C15.573 3.705 13.525 3.538 12 3.538ZM6.845 2.314C8.281 2.174 10.394 2 12 2C13.606 2 15.72 2.174 17.155 2.314C17.8037 2.37494 18.41 2.66298 18.867 3.12732C19.324 3.59165 19.6024 4.20246 19.653 4.852C19.794 6.701 20 9.748 20 12C20 14.252 19.795 17.3 19.653 19.148C19.6026 19.7977 19.3243 20.4088 18.8673 20.8733C18.4102 21.3378 17.8038 21.626 17.155 21.687C15.719 21.826 13.606 22 12 22C10.394 22 8.28 21.826 6.845 21.687C6.19613 21.626 5.58971 21.3376 5.13281 20.8728C4.67592 20.4081 4.39795 19.7968 4.348 19.147C4.205 17.3 4 14.253 4 12C4 9.748 4.205 6.7 4.348 4.852C4.3986 4.20261 4.67683 3.59193 5.13364 3.12762C5.59045 2.6633 6.19652 2.37517 6.845 2.314Z"
        fill="#e30a17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.194 17.128C10.194 16.703 10.54 16.359 10.968 16.359H13.032C13.46 16.359 13.807 16.703 13.807 17.129C13.807 17.555 13.46 17.897 13.032 17.897H10.968C10.7634 17.8975 10.567 17.8168 10.4219 17.6727C10.2768 17.5285 10.1948 17.3326 10.194 17.128Z"
        fill="#e30a17"
      />
      <path
        d="M13.032 6.872C13.032 7.438 12.57 7.897 12 7.897C11.43 7.897 10.968 7.438 10.968 6.872C10.968 6.305 11.43 5.846 12 5.846C12.57 5.846 13.032 6.306 13.032 6.872Z"
        fill="#e30a17"
      />
    </svg>
  );

  const mail = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10_201)">
        <path
          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
          fill="#e30a17"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_201)">
          <rect width="24" height="24" fill="#e30a17" />
        </clipPath>
      </defs>
    </svg>
  );

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));

  const imageUpload = async (imageFile) => {
    const formData = new FormData();
    formData.append("image", imageFile);
    try {
      setLoading(true);
      const Response = await axios.post("common/uploadImage/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${MYToken}`,
        },
      });
      setLoading(false);
      console.log("🚀 ~ imageUpload ~ Response:", Response);
      setImageUrl(Response.data.data.image_url);
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ imageUpload ~ error:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newImageURL = URL.createObjectURL(file);
      setPreviewImage(newImageURL);
      setImageFile(file);
      imageUpload(file);
    }
  };
  // useEffect(() => {
  //   if (imageFile) {
  //     imageUpload();
  //   }
  // }, [imageFile, profiledata]);

  const getprofile = async () => {
    setLoading(true);
    try {
      const Response = await axios.post(
        "/user/get-my-profile",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ getprofile ~ Response:", Response);

      setLoading(false);
      setprofileData(Response.data.data);
    } catch (error) {
      console.log("🚀 ~ getprofile ~ error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  const onSubmit = async () => {
    console.log("🚀 ~ onSubmit ~ values:", values);
    try {
      setLoading(true);
      const Response = await axios.post(
        "user/editProfile",
        {
          image: imageUrl,
          first_name: values.FirstName,
          last_name: values.LastName,
          email: values.Email,
          ph_no: values.Number,
          country_code: values.Countrycode,
          company_name: values.Company,
          position_company: values.Position,
          business_category: values.Industry.toString(),
          country: values.Country.toString(),
          state: values.State,
          city: values.City,
          dob: values.DoB,
          address: values.Address,
          latitude: values.Latitude.toString(),
          longitude: values.Longitude.toString(),
          gender: values.Gender,
          about: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ onSubmit ~ Response:", Response);
      getprofile();
      setEditMode(false);
      setLoading(false);
      setValue("");
      setDob(null);
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error);
      setLoading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (profiledata) {
      setValues({
        FirstName: profiledata.firstname,
        LastName: profiledata.lastname,
        Email: profiledata.email,
        Number: profiledata.phonenumber,
        Countrycode: profiledata.country_code,
        Company: profiledata.company_name,
        Position: profiledata.position_company,
        DoB: profiledata?.dob ? new Date(profiledata?.dob) : null,
        Industry: profiledata.industry,
        Gender: profiledata.gender,
        Address: profiledata.address,
        Latitude: profiledata.latitude,
        Longitude: profiledata.longitude,
        City: profiledata.city,
        State: profiledata.state,
        Country: profiledata.country,
        Password: "",
      });

      setImageUrl(profiledata.image);
      setPreviewImage(profiledata.image);
      if (profiledata.phonenumber && profiledata.country_code) {
        setValue(`+${profiledata.country_code}${profiledata.phonenumber}`);
      }
      if (profiledata?.dob) {
        const [day, month, year] = profiledata.dob.split("/");
        const parsedDate = new Date(`${month}/${day}/${year}`);
        if (!isNaN(parsedDate.getTime())) {
          setDob(parsedDate);
        } else {
          console.error(
            "Invalid date format in profiledata.dob:",
            profiledata.dob
          );
        }
      }
    }
  }, [profiledata]);

  useEffect(() => {
    if (dob) {
      console.log("Setting DOB: ", dob);
      setFieldValue("DoB", dob.toLocaleDateString("en-GB"));
    }
  }, [dob]);
  console.log("🚀 ~ useEffect ~ profiledata:", profiledata);
  const [passwordShown, setPasswordShown] = useState(false);

  const [Countrydata, setCountrydata] = useState([]);
  const [Industrydata, setIndustrydata] = useState();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const GetCountry = async () => {
    try {
      const res = await axios.get("common/getcountry/");
      setCountrydata(res.data.data);
    } catch (error) {
      console.log("🚀 ~ GetCountry ~ error:", error);
    }
  };

  useEffect(() => {
    GetCountry();
  }, []);

  const getIndustry = async () => {
    try {
      const Response = await axios.post("business/getcategory", {
        lang_id: "1",
      });

      setIndustrydata(Response.data.data);
    } catch (error) {
      console.log("🚀 ~ getIndustry ~ error:", error);
    }
  };

  useEffect(() => {
    getIndustry();
  }, []);

  const Map_KEY = "AIzaSyBcsdjbcFxHda59ARD_5OlnUEl5NqO9oJA";
  const MYDeviceToken = JSON.parse(localStorage.getItem("DeviceToken"));
  console.log("🚀 ~ Index ~ MYDeviceToken:", MYDeviceToken);

  const GetMYtoken = async () => {
    try {
      const Response = await axios.post("/auth/getToken", {
        device_id: MYDeviceToken,
        device_type: "A",
      });
      localStorage.setItem("MYToken", JSON.stringify(Response.data.data.token));
    } catch (error) {
      console.log("🚀 ~ GetMYtoken ~ error:", error);
    }
  };

  useEffect(() => {
    GetMYtoken();
  }, []);

  useEffect(() => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber) {
        setCountryCode(parsedNumber.countryCallingCode);
        setMobileNumber(parsedNumber.nationalNumber);
        setFieldValue("Number", parsedNumber.nationalNumber);
        setFieldValue("Countrycode", parsedNumber.countryCallingCode);
      }
    }
  }, [value]);

  return (
    <>
      <Navbar />
      <div className="All-Conatinor-perfect-divv">
        <div
          className="All-Containor-perfect-second-divv"
          style={{
            height: editMode ? "100%" : "80vh",
            width: editMode ? "100%" : "80%",
          }}>
          <div className="main_myprofile_div">
            <div className="main_edit_profile_div">
              <div className="btn_of_edit">
                {editMode ? null : (
                  <button className="theered_btn" onClick={handleEditToggle}>
                    Edit
                  </button>
                )}
              </div>

              <section className={editMode ? "banner" : "banner2"}>
                {/* style={{ flexDirection: editMode ? "column" : " row" }} */}
                <div className="profile_img_div">
                  {editMode ? (
                    <>
                      <input
                        className="profile_pic"
                        type="file"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <label
                        htmlFor="file-input"
                        className="custom-file-upload">
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            loading="lazy"
                            alt="Preview"
                            className="profile_pic"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            src={defultimg}
                            loading="lazy"
                            alt="image"
                            className="profile_pic"
                            style={{ width: "100%" }}
                          />
                        )}
                        <span className="editicon_">{editicon}</span>
                      </label>
                    </>
                  ) : (
                    <img
                      // src={profiledata.image ? profiledata.image : defultimg}
                      src={imageUrl ? imageUrl : defultimg}
                      alt="Profile"
                      loading="lazy"
                      className="profile_pic"
                    />
                  )}
                </div>
                <div className="banner-content">
                  {editMode ? (
                    <div className="edit_profile_from_box">
                      <div
                        className="main_form_div_SU"
                        style={{ width: "100%" }}>
                        <form
                          onSubmit={handleSubmit}
                          className="su_main_form_div">
                          <div className="su_name_main">
                            <div className="su_fname_div">
                              <label htmlFor="FirstName">First Name</label>
                              <div className="su_fname_main_input_div">
                                <img src={profile} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="FirstName"
                                  value={values.FirstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.FirstName && touched.FirstName ? (
                                <p className="errors_msg_login">
                                  {errors.FirstName}
                                </p>
                              ) : null}
                            </div>
                            <div className="su_fname_div">
                              <label htmlFor="LastName">Last Name</label>
                              <div className="su_fname_main_input_div">
                                <img src={profile} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="LastName"
                                  value={values.LastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.LastName && touched.LastName ? (
                                <p className="errors_msg_login">
                                  {errors.LastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="su_Company_main">
                            <div className="su_Company_div">
                              <label htmlFor="Company">Company</label>
                              <div className="su_fname_main_input_div">
                                <img src={Company} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="Company"
                                  value={values.Company}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.Company && touched.Company ? (
                                <p className="errors_msg_login">
                                  {errors.Company}
                                </p>
                              ) : null}
                            </div>
                            <div className="su_Company_div">
                              <label htmlFor="Position">Position</label>
                              <div className="su_fname_main_input_div">
                                <img src={Position} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="Position"
                                  value={values.Position}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.Position && touched.Position ? (
                                <p className="errors_msg_login">
                                  {errors.Position}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="su_Email_number_main">
                            <div className="su_Email_div">
                              <label htmlFor="Email">Email</label>
                              <div className="su_email_main_input_div">
                                <img src={Email} alt="" loading="lazy" />
                                <input
                                  type="email"
                                  name="Email"
                                  value={values.Email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.Email && touched.Email ? (
                                <p className="errors_msg_login">
                                  {errors.Email}
                                </p>
                              ) : null}
                            </div>
                            <div className="su_Mobile_div">
                              <label htmlFor="Number">Mobile</label>
                              <div className="su_Mobile_main_input_div">
                                <img src={Mobile} alt="" loading="lazy" />
                                <PhoneInput
                                  className="PhoneInput_SU"
                                  international
                                  countryCallingCodeEditable={false}
                                  value={value}
                                  onChange={(e) => setValue(e)}
                                />
                              </div>
                              {errors.Number && touched.Number ? (
                                <p className="errors_msg_login">
                                  {errors.Number}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="su_Dob_add_main">
                            <div className="su_Dob_div">
                              <label htmlFor="DoB">Date of Birth</label>
                              <div className="su_Dob_main_input_div">
                                <img src={DateofBirth} alt="" loading="lazy" />
                                <DatePicker
                                  selected={dob}
                                  onChange={(date) => setDob(date)}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  className="date_picker_input"
                                />
                              </div>
                              {errors.DoB && touched.DoB ? (
                                <p className="errors_msg_login">{errors.DoB}</p>
                              ) : null}
                            </div>
                            <div className="su_City_div">
                              <label htmlFor="Industry">Industry</label>
                              <div className="su_Country_main_input_div">
                                <img src={Industry} alt="" loading="lazy" />
                                <select
                                  name="Industry"
                                  value={values.Industry}
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select an Industry</option>
                                  {Industrydata?.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="su_City_add_main">
                            <div className="su_add_div">
                              <label htmlFor="Address">Address</label>
                              <div className="su_Address_main_input_div">
                                <img src={Address} alt="" loading="lazy" />
                                <Autocomplete
                                  apiKey={Map_KEY}
                                  onPlaceSelected={(place) => {
                                    setFieldValue(
                                      "Address",
                                      place.formatted_address
                                    );
                                    setFieldValue(
                                      "Latitude",
                                      place.geometry.location.lat()
                                    );
                                    setFieldValue(
                                      "Longitude",
                                      place.geometry.location.lng()
                                    );
                                  }}
                                  placeholder=""
                                  value={values.Address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.Address && touched.Address ? (
                                <p className="errors_msg_login">
                                  {errors.Address}
                                </p>
                              ) : null}
                            </div>
                            <div className="su_City_div">
                              <label htmlFor="City">City</label>
                              <div className="su_city_main_input_div">
                                <img src={City} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="City"
                                  value={values.City}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.City && touched.City ? (
                                <p className="errors_msg_login">
                                  {errors.City}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="su_City_add_main">
                            <div className="su_City_div">
                              <label htmlFor="State">State</label>
                              <div className="su_city_main_input_div">
                                <img src={Country} alt="" loading="lazy" />
                                <input
                                  type="text"
                                  name="State"
                                  value={values.State}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              {errors.State && touched.State ? (
                                <p className="errors_msg_login">
                                  {errors.State}
                                </p>
                              ) : null}
                            </div>
                            <div className="su_Country_div">
                              <label htmlFor="Country">Country</label>
                              <div className="su_Country_main_input_div">
                                <img src={Country} alt="" loading="lazy" />
                                <select
                                  name="Country"
                                  value={values.Country}
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select a country</option>
                                  {Countrydata?.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.Country && touched.Country ? (
                                <p className="errors_msg_login">
                                  {errors.Country}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="su_Password_add_main">
                            <div className="su_City_div">
                              <label htmlFor="Gender">Gender</label>
                              <div className="su_Country_main_input_div">
                                <img src={Gender} alt="" loading="lazy" />
                                <select
                                  name="Gender"
                                  value={values.Gender}
                                  onChange={handleChange}
                                  onBlur={handleBlur}>
                                  <option value="">Select a gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                              {errors.Gender && touched.Gender ? (
                                <p className="errors_msg_login">
                                  {errors.Gender}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn_primry"
                            style={{ textAlign: "center" }}>
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="profile_show_data">
                      <h2>
                        Hello,
                        {`${profiledata?.firstname} ${profiledata?.lastname}`}
                      </h2>
                      <p>
                        {mail} :- {profiledata?.email}
                      </p>
                      <p>
                        {mobile} :-{" "}
                        {`${profiledata?.country_code} ${profiledata?.phonenumber}`}
                      </p>
                    </div>
                  )}
                </div>
              </section>
              {editMode ? null : (
                <svg
                  className="svg-container"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 24 150 28"
                  preserveAspectRatio="none">
                  <defs>
                    <path
                      id="gentle-wave"
                      d="M-160 44c30 0
        58-18 88-18s
        58 18 88 18
        58-18 88-18
        58 18 88 18
        v44h-352z"
                    />
                  </defs>
                  <g className="waves">
                    <use
                      xlinkHref="#gentle-wave"
                      x="50"
                      y="0"
                      fill=" #c3002f"
                      fillOpacity=".2"
                    />
                    <use
                      xlinkHref="#gentle-wave"
                      x="50"
                      y="3"
                      fill=" #c3002f"
                      fillOpacity=".5"
                    />
                    <use
                      xlinkHref="#gentle-wave"
                      x="50"
                      y="6"
                      fill=" #c3002f"
                      fillOpacity=".9"
                    />
                  </g>
                </svg>
              )}
              {!loading && <div></div>}
              {loading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={true}>
                    <span className="loader"></span>
                  </Backdrop>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AnimatedSVG;
