import React from "react";
import "./Thankyou.css";
import checkimg from "../../assets/check.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();

  const route = useLocation();
  const name = route.state?.checkPathname;

  return (
    <>
      <div class="main-sec">
        <div class="text">
          <img src={checkimg} alt="" loading="lazy" />

          <h1>Thank You For Contacting Us</h1>

          <p>
            We appreciate that you've taken the time to write us. <br />
            We'll get back to you very soon.
          </p>

          <Link to={"/"} className="button_back">
            Go to Website
          </Link>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
