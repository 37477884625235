import { initializeApp } from "firebase/app";

import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";

// ? old project
const firebaseConfig = {
  apiKey: "AIzaSyB6eayv8cVAKkMxSSi3dpOAp80vwqqK7-I",
  authDomain: "trukafrica-8d294.firebaseapp.com",
  projectId: "trukafrica-8d294",
  storageBucket: "trukafrica-8d294.appspot.com",
  messagingSenderId: "771656327713",
  appId: "1:771656327713:web:dcfa9f7e54345d11e57960",
  measurementId: "G-LW9BYKY5Z8",
};
// ?

export const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);
const storage = getStorage(app);
export { storage };
// const getMessaging = getMessaging(app);

const GoogleProvider = new GoogleAuthProvider();

const FacebookProvider = new FacebookAuthProvider();

export { auth, GoogleProvider, FacebookProvider, db };
