import { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GlobalContext } from "../../../Globalcontext";
import "./model.css";
import { useFormik } from "formik";
import { ForgotSchemas, ChnagepasswordSchemas } from "../../../schemas";
import axios from "../../../Common/API/index";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

export const ForgotPasswordmodal = () => {
  const { FiveModalShow, setFiveModalShow } = useContext(GlobalContext);
  const initialValues = {
    Email: "",
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotSchemas,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      resetForm();
    },
  });

  return (
    <Modal show={FiveModalShow} onHide={setFiveModalShow} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title className="newtitle">Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="Modalbody_forgot">
        <div className="forgot_main_div">
          <form action="" className="forget_form" onSubmit={handleSubmit}>
            <div className="enter_email_div">
              <label htmlFor="html">Email </label>
              <div className="email_enter_input">
                <input
                  type="email"
                  name="Email"
                  id=""
                  placeholder="Enter your email address"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors.Email && touched.Email ? (
                <p className="errors_msg_p_fp">{errors.Email} </p>
              ) : null}
            </div>
            <button
              type="submit"
              className="btn_primry_fp"
              style={{ textAlign: "center" }}>
              Submit
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ChangepasswordModel = () => {
  const { changePasswordshow, setchangePasswordshow } =
    useContext(GlobalContext);
  const handleClose = () => {
    setchangePasswordshow(false);
  };
  const [loading, setloading] = useState(false);

  const initialValues = {
    oldpassword: "",
    Newpassword: "",
    confirmpassword: "",
  };
  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  const onSubmit = async (values, { resetForm }) => {
    try {
      setloading(true);
      const Response = await axios.post(
        "auth/changePassword",
        {
          old_password: values.oldpassword,
          new_password: values.Newpassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      setloading(false);
      console.log("🚀 ~ onSubmit ~ Response:", Response);
      if (Response?.status === 200) {
        toast.success("Your password has been changed successfully", {
          position: "top-right",
        });
        setchangePasswordshow(false);
        resetForm();
      }
    } catch (error) {
      setloading(false);
      toast.error(error?.response?.data?.message, {
        position: "top-right",
      });
      console.log(error);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ChnagepasswordSchemas,
    onSubmit,
  });
  // : () => {
  // console.log(values);
  // resetForm();
  // },
  return (
    <>
      <Toaster />
      <Modal
        size="ms"
        show={changePasswordshow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Header closeButton className="model_title">
          <Modal.Title>Chnage Password</Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton className="modelbg">
          <div className="main_change_div">
            <form action="" className="pass_form" onSubmit={handleSubmit}>
              <div className="oldpass">
                <label htmlFor="text">Old Password</label>
                <div className="old_pass_input_div">
                  <input
                    type="text"
                    id="oldpassword"
                    name="oldpassword"
                    value={values.oldpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="old Password"
                  />
                </div>
                {errors.oldpassword && touched.oldpassword ? (
                  <p className="errors_msg_p_fp">{errors.oldpassword} </p>
                ) : null}
              </div>
              <div className="oldpass">
                <label htmlFor="text">New Password</label>
                <div className="old_pass_input_div">
                  <input
                    type="text"
                    id="Newpassword"
                    name="Newpassword"
                    value={values.Newpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="New password"
                  />
                </div>
                {errors.Newpassword && touched.Newpassword ? (
                  <p className="errors_msg_p_fp">{errors.Newpassword} </p>
                ) : null}
              </div>
              <div className="oldpass">
                <label htmlFor="text">Confirm Password</label>
                <div className="old_pass_input_div">
                  <input
                    type="text"
                    id="confirmpassword"
                    name="confirmpassword"
                    value={values.confirmpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Confirm Password"
                  />
                </div>
                {errors.confirmpassword && touched.confirmpassword ? (
                  <p className="errors_msg_p_fp">{errors.confirmpassword} </p>
                ) : null}
              </div>
              <div className="pass_sub_btn">
                <button
                  type="submit"
                  className="btn_primry_fp"
                  style={{ textAlign: "center" }}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export const LogoutModel = () => {
  const { LogoutModalshow, setLogoutModalshow } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleClose = () => {
    setLogoutModalshow(false);
  };
  const MYToken = JSON.parse(localStorage.getItem("MYToken"));

  // const handlelogout = () => {
  //   setLogoutModalshow(false);
  //   // localStorage.removeItem("DeviceToken");
  //   localStorage.removeItem("MYToken");
  //   localStorage.removeItem("Userdata");
  //   navigate("/Login");
  // };
  const handleLogout = async () => {
    console.log("🚀 ~ handleLogout ~ handleLogout:");
    try {
      const response = await axios.get("auth/logout", {
        headers: {
          Authorization: `Bearer ${MYToken}`,
        },
      });
      navigate("/Login");
      setLogoutModalshow(false);
      localStorage.removeItem("Userdata");
      console.log("🚀 ~ handleLogout ~ response:", response);
    } catch (error) {
      console.log("🚀 ~ handleLogout ~ error:", error);
    }
  };

  return (
    <>
      <Modal
        size="sm"
        show={LogoutModalshow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Body closeButton className="modelbg">
          <Modal.Title style={{ textAlign: "center" }}>Log out !</Modal.Title>
          <p>Are you sure, you want to Logout ?</p>

          <div className="btn_of_delte_model">
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <button
              onClick={() => handleLogout()}
              className="btn_primry_fp"
              style={{ textAlign: "center" }}>
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export const LoginModel = () => {
  const { FirstModalShow, setFirstModalShow } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleClose = () => {
    setFirstModalShow(false);
  };
  const handleloginClose = () => {
    navigate("/Login");
    setFirstModalShow(false);
  };
  const handlesignupClose = () => {
    navigate("/Signup");
    setFirstModalShow(false);
  };
  return (
    <>
      <Modal
        size="sm"
        show={FirstModalShow}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}>
        <Modal.Header closeButton className="model_title"></Modal.Header>
        <Modal.Body closeButton className="modelbg">
          <p style={{ textAlign: "center" }}>Please login or sign up!</p>

          <div className="btn_of_delte_model">
            <button
              // variant="secondary"
              className="btn_primry_fp"
              onClick={handleloginClose}>
              Login
            </button>
            <button
              className="btn_primry_fp"
              style={{ textAlign: "center" }}
              onClick={handlesignupClose}>
              Sign up
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
