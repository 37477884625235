import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import { toast, Toaster } from "react-hot-toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { LoginSchemas } from "../../schemas/index";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../Globalcontext";
import axios from "../../Common/API/index";
import { Backdrop, CircularProgress } from "@mui/material";
const Index = () => {
  const { FiveModalShow, setFiveModalShow, MyBusinessData, setMyBusinessData } =
    useContext(GlobalContext);
  const initialValues = {
    Email: "",
    Password: "",
  };
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };
  const MYDeviceToken = JSON.parse(localStorage.getItem("DeviceToken"));
  const GetMYtoken = async () => {
    try {
      const Response = await axios.post("/auth/getToken", {
        device_id: MYDeviceToken,
        device_type: "A",
      });
      localStorage.setItem("MYToken", JSON.stringify(Response.data.data.token));
    } catch (error) {
      console.log("🚀 ~ GetMYtoken ~ error:", error);
    }
  };
  useEffect(() => {
    GetMYtoken();
  }, []);

  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  console.log("🚀 ~ Index ~ MYToken:", MYToken);
  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const Response = await axios.post(
        "auth/signIn",
        { email: values.Email, password: values.Password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      resetForm();
      navigate("/");
      localStorage.setItem(
        "Userdata",
        JSON.stringify(Response.data.data.detail)
      );
      console.log("🚀 ~ onSubmit ~ Response:", Response);
      setloading(false);
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error);
      setloading(false);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchemas,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);
    //   navigate("/");
    //   resetForm();
    // },
  });

  return (
    <>
      <div className="main_login_page_div">
        <div className="back_btn_div_auth" onClick={() => navigate(-1)}>
          <span>&larr;</span>
        </div>
        <div className="sub_main_div_of_login">
          <div className="main_login_card">
            <div className="main_form_div">
              <div className="div_of_logo" onClick={() => navigate("/")}>
                <img src={logo} alt="" loading="lazy" />
                <p>Driving Prosperity for Turkish and African Enterprises</p>
              </div>
              <form onSubmit={handleSubmit} className="login_form">
                <div className="Email_box_login">
                  <label htmlFor="email">Email</label>
                  <div className="input_div_login">
                    <input
                      type="email"
                      id="email"
                      name="Email"
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.Email && touched.Email ? (
                    <p className="errors_msg_login">{errors.Email}</p>
                  ) : null}
                </div>
                <div className="Email_box_login">
                  <label htmlFor="Password">Password</label>
                  <div className="input_div_pass">
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="Password"
                      name="Password"
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <i
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon
                        icon={passwordShown ? faEyeSlash : faEye}
                        style={{ color: "white" }}
                      />
                    </i>
                  </div>
                  {errors.Password && touched.Password ? (
                    <p className="errors_msg_login">{errors.Password}</p>
                  ) : null}
                </div>
                <div className="div_of_button_">
                  <button
                    type="submit"
                    className="btn_primry"
                    style={{ textAlign: "center" }}>
                    Login
                  </button>
                  <div
                    className="div_forgot_password"
                    onClick={() => setFiveModalShow(true)}>
                    <p>Forgot password?</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="main_signin_div">
            <p>
              Don’t have an account ? {""}
              <NavLink className="navlink" to="/Signup">
                Sign up
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            {/* <CircularProgress color="inherit" /> */}
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
