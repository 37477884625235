import { useState, useEffect, useRef, useContext } from "react";
import "./Nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { GlobalContext } from "../../../Globalcontext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import axios from "../../../Common/API/index";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";

const Navbar = () => {
  const {
    MyBusinessData,
    setMyBusinessData,
    profiledata,
    setprofileData,
    setchangePasswordshow,
    setLogoutModalshow,
    setFirstModalShow,
  } = useContext(GlobalContext);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setLang] = useState();
  const menuref = useRef();
  const navigate = useNavigate();
  const [open, setIsOpen] = useState(false);
  const [onShow, setOnShow] = useState(false);
  const [Industrydata, setIndustrydata] = useState();
  const location = useLocation();
  // Utility function to get current window dimensions
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  // Set screen dimensions on window resize
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  // Close menu when clicking outside of it
  useEffect(() => {
    const handler = (event) => {
      if (menuref.current && !menuref.current.contains(event.target)) {
        setIsOpen(false);
        setOnShow(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  // Smooth scroll to section 3 when path is "/" and section id is provided
  useEffect(() => {
    if (location.pathname === "/" && location.hash === "#AboutUs") {
      const section = document.getElementById("AboutUs");
      if (section) {
        const yOffset = -100;
        const y =
          section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
    if (location.pathname === "/" && location.hash === "#Home") {
      const section = document.getElementById("Home");
      if (section) {
        const yOffset = -100;
        const y =
          section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);
  // Handle menu toggle
  const toggle = (item) => {
    setIsOpen(false);
  };
  const handleHide = () => {
    setIsOpen(!open);
  };
  // Update the language based on the current path
  useEffect(() => {
    setLang(location.pathname);
  }, [location]);
  // Handle logout
  const MYToken = JSON.parse(localStorage.getItem("MYToken"));
  // Get profile data

  const getprofile = async () => {
    try {
      const Response = await axios.post(
        "/user/get-my-profile",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      setprofileData(Response.data.data);
    } catch (error) {
      console.log("🚀 ~ getprofile ~ error:", error);
    }
  };
  useEffect(() => {
    getprofile();
  }, []);
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));
  const logout = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4182)">
        <path
          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4182">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const profile_icon = (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2956 0C5.95085 0 0 5.95085 0 13.2956C0 20.6403 5.95085 26.5912 13.2956 26.5912C20.6403 26.5912 26.5912 20.6403 26.5912 13.2956C26.5912 5.95085 20.6403 0 13.2956 0ZM13.2956 5.14668C15.9011 5.14668 18.0134 7.25896 18.0134 9.86447C18.0134 12.47 15.9011 14.5823 13.2956 14.5823C10.6901 14.5823 8.5778 12.47 8.5778 9.86447C8.5778 7.25896 10.6901 5.14668 13.2956 5.14668ZM13.2956 23.5889C10.1486 23.5889 7.32866 22.1629 5.44154 19.9327C6.44943 18.0348 8.42233 16.7267 10.7222 16.7267C10.8509 16.7267 10.9796 16.7482 11.1029 16.7857C11.7998 17.0108 12.5289 17.1556 13.2956 17.1556C14.0622 17.1556 14.7967 17.0108 15.4883 16.7857C15.6116 16.7482 15.7403 16.7267 15.8689 16.7267C18.1688 16.7267 20.1417 18.0348 21.1496 19.9327C19.2625 22.1629 16.4426 23.5889 13.2956 23.5889Z"
        fill="#fff"
      />
    </svg>
  );
  const changepass = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_3887)">
        <path
          d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_3887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const Userid = Userdata?.id;
  // ? get my Business start api
  const GetMyBusiness = async () => {
    try {
      const resposne = await axios.post(
        "business/get",
        { UserId: Userid?.toString() },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MYToken}`,
          },
        }
      );
      console.log("🚀 ~ GetMyBusiness ~ resposne:", resposne);
      setMyBusinessData(resposne.data.data);
    } catch (error) {
      console.log("🚀 ~ GetMyBusiness ~ error:", error);
    }
  };
  useEffect(() => {
    GetMyBusiness();
  }, []);
  // ? get my Business start api
  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar" style={{ justifyContent: "space-between" }}>
          <NavLink to={{ pathname: "/", hash: "#Home" }} className="logo">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100%" }}
              loading="lazy"
            />
          </NavLink>
          <NavLink to={{ pathname: "/", hash: "#Home" }} className="logo2">
            <img
              src={logo2}
              alt="Logo 2"
              style={{ width: "70%" }}
              loading="lazy"
            />
          </NavLink>
          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <NavLink
                to={{ pathname: "/", hash: "#AboutUs" }}
                onClick={() => toggle("")}
                className={({ isActive }) =>
                  location.pathname === "/" && location.hash === "#AboutUs"
                    ? "active"
                    : ""
                }>
                About Us
              </NavLink>
            </li>
            <li>
              {Userdata ? (
                <NavLink
                  to="/Countries"
                  onClick={() => toggle("")}
                  className={({ isActive }) => (isActive ? "active" : "")}>
                  Countries
                </NavLink>
              ) : (
                <button
                  onClick={() => setFirstModalShow(true)}
                  className="inactive_link">
                  Countries
                </button>
              )}
            </li>
            <li>
              {Userdata ? (
                <NavLink
                  to="/Industries"
                  onClick={() => toggle("")}
                  className={({ isActive }) => (isActive ? "active" : "")}>
                  Industries
                </NavLink>
              ) : (
                <button
                  onClick={() => setFirstModalShow(true)}
                  className="inactive_link">
                  Industries
                </button>
              )}
            </li>
            <li>
              <NavLink
                to="/ContactUs"
                onClick={() => toggle("")}
                className={({ isActive }) => (isActive ? "active" : "")}>
                Contact
              </NavLink>
            </li>
            {Userdata ? (
              <>
                <li>
                  {MyBusinessData ? (
                    <button
                      className="download_btn"
                      style={{ marginRight: "1rem" }}>
                      <NavLink
                        to="/ShowMyBusiness"
                        offset={-100}
                        className={({ isActive }) =>
                          isActive ? "active" : ""
                        }>
                        Show My Business
                      </NavLink>
                    </button>
                  ) : (
                    <button
                      className="download_btn"
                      style={{ marginRight: "1rem" }}>
                      <NavLink
                        to="/AddYourBusiness"
                        offset={-100}
                        className={({ isActive }) =>
                          isActive ? "active" : ""
                        }>
                        Add Your Business
                      </NavLink>
                    </button>
                  )}
                </li>
                <li>
                  <MDBDropdown group>
                    <MDBDropdownToggle className="main_dropdown_div">
                      <div className="profile_pic_box">
                        <img src={profiledata?.image} alt="" loading="lazy" />
                      </div>
                      <span className="USer_name">
                        {profiledata?.firstname}
                      </span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        link
                        onClick={() => navigate("/Profile")}>
                        <span className="inner_item">
                          {profile_icon}
                          My profile
                        </span>
                      </MDBDropdownItem>
                      <MDBDropdownItem link onClick={setchangePasswordshow}>
                        <span className="inner_item">
                          {changepass}
                          Change Password
                        </span>
                      </MDBDropdownItem>
                      <MDBDropdownItem link>
                        <span
                          className="inner_item"
                          onClick={setLogoutModalshow}>
                          {logout}
                          Log out
                        </span>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </li>
              </>
            ) : (
              <li>
                <button
                  className="download_btn"
                  style={{ marginRight: "1rem" }}>
                  <NavLink
                    to="/Login"
                    offset={-100}
                    className={({ isActive }) => (isActive ? "active" : "")}>
                    Log in
                  </NavLink>
                </button>
                <button className="download_btn">
                  <NavLink
                    to="/Signup"
                    offset={-100}
                    className={({ isActive }) => (isActive ? "active" : "")}>
                    Sign Up
                  </NavLink>
                </button>
              </li>
            )}
          </ul>
          <div className="menubtn">
            <Hamburger onToggle={handleHide} toggled={open} />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
